import React, { Fragment } from "react"

import Div100vh from "react-div-100vh"

import Navbar from "./Navbar"

import "bootstrap/dist/css/bootstrap.min.css"
import "../styles/Font.css"
import "../styles/Global.css"
import "../styles/page_protectParadise.css"

const LayoutPage = props => {
  return (
    <Fragment>
      <div>
        <div className="row no-gutters">
          <div className="col">
            <Navbar
              typeLeftLogo="3"
              typeRightLogo="2"
              typeArrowLogo_mobile="2"
              typeRightLogoMobile="2"
              typeCenterLogo_mobile="2"
              // background='black'
              light={props.light}
              isMobile={props.isMobile}
            />
          </div>
        </div>

        <div className="row no-gutters">
          <div className="col">
            <main>{props.children}</main>
          </div>
        </div>

        <div className="row no-gutters">
          <div className="col">
            <footer>
              <script
                src="https://code.jquery.com/jquery-3.4.1.slim.min.js"
                integrity="sha384-J6qa4849blE2+poT4WnyKhv5vZF5SrPo0iEjwBvKU7imGFAV0wwj1yYfoRSJoZ+n"
                crossOrigin="anonymous"
              ></script>
              <script
                src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js"
                integrity="sha384-Q6E9RHvbIyZFJoft+2mJbHaEWldlvI9IOYy5n3zV9zzTtmI3UksdQRVvoxMfooAo"
                crossOrigin="anonymous"
              ></script>
              <script
                src="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js"
                integrity="sha384-wfSDF2E50Y2D1uUdj0O3uMBJnjuUD4Ih7YwaYd1iqfktj0Uod8GCExl3Og8ifwB6"
                crossOrigin="anonymous"
              ></script>
            </footer>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default LayoutPage
