import React, { useEffect, useState } from "react"
import {graphql} from 'gatsby'

import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'


import SEO from "../components/seo"
import FooterPages from '../components/FooterPages';
import LayoutPages from '../components/LayoutPages'
import Video from "../components/Video"
import Form from "../components/Form"
import {IsMobileDevice, CleanArrayImages} from "../utils/index"
import '../styles/page_protectParadise.css'
import arrow from '../../assets/images/icons/arrow_color.png'
import AgeGate2 from "../components/agegate"
/* import icon_instagram from '../../assets/images/icons/instagram.svg'
import icon_facebook from '../../assets/images/icons/facebook.svg'
 */


const ProtectParadise = ({data}) => {
    let item = data.allVideos.nodes[0];
    const [isMobile, setIsMobile] = useState(false)
    const [Images] = useState(CleanArrayImages(data))
    const video = 'https://firebasestorage.googleapis.com/v0/b/casacorona.appspot.com/o/videos%2Fprotect_paradise_02.mp4?alt=media&token=7b1a781f-02ea-478f-a370-83b14850c412'
    const videoMobile = 'https://firebasestorage.googleapis.com/v0/b/casacorona.appspot.com/o/videos%2FprotectParadiseMobile.mp4?alt=media&token=eb0b3485-d6e3-473f-8a6a-828d52e8b845'
    const [age, setAge] = useState(true);

      
  let items = []
  data.allCleanups.nodes.map(function(node){
    items.push(node)
  })

    useEffect(() => {
        let local = sessionStorage.getItem('age')
        if (local) setAge(false)
        setIsMobile(IsMobileDevice())
    }, [])

    useEffect(() => {
        window.dataLayer.push({
            brand: "Corona",
            Zone: "SAZ",
            country: "ARG",
            city: "CABA",
            campaign: `${
                window.location.search.includes("?cmp=")
                    ? window.location.search.split("?cmp=")[1].split("&")[0]
                    : ""
            }`,
            cms: "0",
            event: "pageView",
            language: "es",
            login: false,
            pageName: "Protect Paradise",
            pageType: "Content page",
            SiteType: "Brand",
            product: "NA",
            sku: "NA",
            userUid: "",
            url: "https://www.cervezacorona.com.ar/protectparadise",
            url_campaign: `${
                window.location.search.includes("utm_campaign=")
                    ? window.location.search.split("utm_campaign=")[1]
                    : ""
            }`,
            Step_number: "" ,
        })
    }, [])

    return (
        !age ? (
            <LayoutPages isMobile={isMobile}>
            <SEO title="Protect Paradise"/>

           

            {/* coronaXParley */}
            <div className="row text-center justify-content-center logomaradisemargin" id="coronaParley">
                <div className="col">
                    <Img loading={'eager'} fluid={Images['CoronaXParley']} className={'d-inline-block img_coronaParley'} />
                </div>
            </div>
           
            <div className="row text-center justify-content-center">
                <div className="col">
                    <p className="paradise_p">
                        Junto a Parley for the Oceans trabajamos hace años para terminar con <br/>
                        la contaminación plástica marina<br/>
                        <br/>
                        Desde la campaña El plástico no pertenece al océano,<br/>
                        organizamos limpiezas de la costa en diferentes puntos del país durante<br/>
                        todo el año, generando conciencia ambiental,<br/>
                        pero también actuando y dando el ejemplo<br/>
                        <br/>
                        Para poder disfrutar de nuestras playas, lagos, ríos y paisajes, primero <br/>
                        tenemos que cuidarlos.<br/>
                        <br/>
                        ¡Sumate a los próximos cleanups!<br/>
                    </p>
                </div>
            </div>

             {/* button smooth scroll */}
             <div className="row ppRowSocialScroll smooth-scroll scrollmarginprotectparadise ">
               {/* <div className="col-auto ppSocial">
                    <div className="footerIcon">
                        <a href="http://www.instagram.com" target="_blank"> <img src={icon_instagram} className="footerInstagram"/></a> 
                    </div>
                    <div className="footerIcon">
                        <a href="http://www.facebook.com" target="_blank"><img src={icon_facebook} className="footerFacebook"/></a> 
                    </div>
                </div>*/}
                <div className="col text-center ">
                    <a href="#coronaParley">
                        <img loading={'eager'} src={arrow} alt="ButtonScroll" className="ppButtonScroll"/>
                    </a>
                </div>
            </div>
            
            {/* video */}
            
            <div className="row">
                <div className="col">

                    <Video  src={isMobile ? item.video_mobile : item.video} isMobile={isMobile} autoplay muted />

                </div>
            </div>

            
            {/* background and form */}
            <div className="row ppContentBackgroundForm">
                <div className="col">
                    <BackgroundImage 
                        tag="footerImg"
                        loading={'eager'}
                        style={{backgroundPosition:'top center', backgroundSize:'cover'}}
                        className={'backgroundFooter'}
                        fluid = {isMobile ? Images['ProtectFoot_Mobile'] : Images['ProtectFoot']}
                        backgroundColor={`#fff`}>

                        <div className="row ppContentFormCasaCorona">
                            <div className="col">
                                <div className="row text-center">
                                    <div className="col">
                                        <h1 className="title-form">Registrate acá</h1>
                                    </div>
                                </div>
                                <Form cleanups={items}/>
                            </div>
                        </div>
                        
                    </BackgroundImage>
                </div>
                <FooterPages />
            </div>

        </LayoutPages>
        ):(
            <AgeGate2 path="/protectparadise"/>
        )
       
        
    );
}

export const backgrounds = graphql`
    query protectParadise {
      allVideos(filter: {site: {eq: "protectparadise"}}) {
            nodes {
              site
              video
              video_mobile
            }
      }
    allCleanups {
      nodes {
        datetime
      }
    }
        allFile(filter: {sourceInstanceName: {eq: "page_ProtectParadise"}}) {
            edges {
                node {
                    name
                        childImageSharp {
                            fluid (maxWidth: 1200) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }    
                }
        }
    }`;
 
export default ProtectParadise;
