import React from 'react'

import '../styles/Form.css'

const Form = ({cleanups}) => {

    const stepUpTime = (e) => {
        e.preventDefault();
        document.querySelector('input[type=time]').stepUp(60); //60min
    }

    const stepDownTime = (e) => {
        e.preventDefault();
        document.querySelector('input[type=time]').stepDown(60); //60min
    }

    return (
        <div className="container-form">
            <div className="row pt-5 px-3">
                <div className="col">

                    <form>
                        <div className="form-group border row">
                            <div className="col px-0">
                                <input required type="text" className="form-control" placeholder="Nombre y Apellido"></input>
                            </div>
                        </div>

                        <div className="form-group border row">
                            <div className="col px-0">
                                <input required type="number" className="form-control" placeholder="DNI"></input>
                            </div>
                        </div>

                        <div className="form-group border row">
                            <div className="col px-0">
                                <input required type="email" className="form-control" placeholder="E-mail"></input>
                            </div>
                        </div>

                        <div className="form-group row mb-0">
                            <div className="col-lg-3 col-4 px-0 pr-2">
                                <input required type="number" className="form-control border" placeholder="Cód. área"></input>
                            </div>
                            <div className="col-lg-9 col-8 px-0 pl-2 form-group">
                                <input required type="number" className="form-control border" placeholder="Teléfono"></input>
                            </div>
                        </div>

                        <div className="form-group border row">
                            <div className="col px-0">
                                <input required type="text" className="form-control" placeholder="Provincia"></input>
                            </div>
                        </div>

                        <div className="form-group border row">
                            <div className="col px-0">
                                <input required type="text" className="form-control" placeholder="Ciudad"></input>
                            </div>
                        </div>

                        <div className="form-group border row form-group-time row">  
                            <div className="col-11 px-0">
                                <select  required  className="form-control" placeholder="Clean Up">
                                    <option value={''}>Clean Up</option>
                                    {cleanups.map(function(cleanup){
                                        let date = new Date(cleanup.datetime)
                                        return(
                                          <option value="volvo">{date.toLocaleDateString()}</option>
                                        )
                                    })}

                                </select>
                            </div>
                          {/*  <div className="col-1 p-0 pr-3 form-control">
                                <div className="row no-gutters text-center">
                                    <div className="col">
                                        <button className="form-btn-time" onClick={e => stepUpTime(e)}>
                                            <svg className="bi bi-chevron-up" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 01.708 0l6 6a.5.5 0 01-.708.708L8 5.707l-5.646 5.647a.5.5 0 01-.708-.708l6-6z" clipRule="evenodd"/>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <div className="row no-gutters text-center">
                                    <div className="col mb-1">
                                        <button className="form-btn-time" onClick={e => stepDownTime(e)}>
                                            <svg className="bi bi-chevron-down" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 01.708 0L8 10.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z" clipRule="evenodd"/>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>*/}
                        </div>

                        <div className="form-check row text-center mt-5">
                            <div className="col">
                                <label className="row checkbox d-inline-flex align-items-center justify-content-center">
                                    <input required className="col-auto" type="checkbox" id="conditions"/>
                                    <span style={{minWidth:'14px', minHeight:'40px'}}></span> 
                                    <span className="col-10 pt-3 px-0">He leído y acepto bases y condiciones, políticas de privacidad y activaciones de marketing.</span>
                                </label>
                            </div>
                        </div>

                        <div className="row text-center">
                            <div className="col">
                                <button type="submit" className="btn btn-submit">Enviar</button>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>   
    )
}
 
export default Form;