import React, { useEffect, useRef } from "react"
import Div100vh from 'react-div-100vh'
import '../styles/video.css'

function Video  ({isMobile, src})  {
    const videRef = useRef(null);
    useEffect(() => {
        let bgVideo;
        if (videRef.current){
            bgVideo = videRef.current;
            bgVideo.pause();
            bgVideo.muted = true;
            bgVideo.play()
        }

        return ()=>{
            if (bgVideo){
                bgVideo.pause()
            }

        }
    },[])

    return (

            <div className="row contentVideo">
                <div className="col">
                    {isMobile &&
                    <video ref={videRef} className="pageVideo" playsInline loop muted autoPlay disablePictureInPicture="true">
                        <source src={src}/>
                    </video>
                    }
                    {!isMobile &&
                    <video ref={videRef} className="pageVideo" playsInline loop muted autoPlay disablePictureInPicture="true">
                        <source src={src}/>
                    </video>
                    }

                </div>
            </div>

    )

}
export default Video



